













































































































































































































































































































































































































































































































































































































































































































































































































import 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import {DateTimeFormatOptions} from "vue-i18n";
import Multiselect from 'vue-multiselect';
import {Prop} from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import {Ax} from "@/utils";
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import {setNameLang} from "@/modules/budget-request/components/js/some-functions";
import BudgetHeaderBip from '@/modules/budget/bip/budget-header-bip.vue';
import FileLoad from '@/modules/budget/bip/file-load.vue';
import {
    BipProjectDataClass,
    CriteriaIndicators,
    RowNewFinanceData,
    RowOldFinanceData,
    findItem,
    getNumber,
    getRowKey,
    makeToast,
    sortByField,
    checkFinDoubles,
    checkFinNulls,
    checkFinCosts,
    getBipTitle,
    getCardTitle,
    getFilterTitle,
    getTFieldTitle,
    getSmsTitle
} from './bip-types';
import i18nService from "@/services/i18n";

// декоратор @Component указывает, что класс — это компонент Vue
@Component({
    name: 'bip-card',
    components: {
        DatePicker,
        'multiselect': Multiselect,
        'c-budg-form-lst': CBudgetFormsList,
        BudgetHeaderBip,
        FileLoad
    }
})

export default class BipCard extends Vue {
    $refs!: {
        budgetHeader: HTMLFormElement;
    };
    @Prop({
        required: false,
        default: false
    })
    private showForm!: boolean;

    @Prop({
        required: false,
        default: false
    })
    private backList!: boolean;

    @Prop({
        required: false,
        default: true
    })
    private reading!: boolean;

    @Prop({
        required: false,
        default: false
    })
    private editSection4!: boolean;

    @Prop({
        required: false,
        default: false
    })
    private saving!: boolean;

    @Prop({
        required: false,
        default: false
    })
    private updateMark!: boolean;

    @Prop({
        required: true,
        default: {}
    })
    private location: any;

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private abpBase!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private regAbpBase!: any[];

    @Prop({
        required: true,
        default: () => {
            return []
        }
    })
    private regionList!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private dataTypeList!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private spfList!: any[]

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private directionList!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private gpList!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private expTypes!: any[];

    @Prop({
        required: false,
        default: () => {
            return new Map()
        }
    })
    private criteriaValues!: Map<any, any>;

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private bipLinkCriterias!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private bipList!: any[];

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private prevBipList!: any[];

    @Prop({
        required: true,
        default: undefined
    })
    private bip!: BipProjectDataClass;

    @Prop({
        required: false,
        default: undefined
    })
    private variant: any;

    private agrObj: any = null;
    private bipYear = 0;
    private begYear = 0;
    private endYear = 0;

    private bipProjectData: BipProjectDataClass = new BipProjectDataClass();
    private selectedBip: BipProjectDataClass | null = null;
    private selDirection: any = {};
    private selObject: any = {};
    private selObjectType: string = '';
    private radioOptions: any [] = [
        {text: '-1', value: -1},
        {text: '0', value: 0},
        {text: '1', value: 1}
    ];
    private calcFlds: any [] = [
        'value',
        'weight',
        'max',
        'min'
    ];

    private histBase: any[] = [];
    private prgList: any[] = [];
    private regPrgList: any[] = [];

    private curKatoRegion: any = {localityList: [{code: ''}], code: ''};
    private curHeader: any;

    public async created() {
        console.log(new Date().toISOString(), 'created bip-card');
        document.addEventListener('click', this.closeOpenMore, true);

        this.$watch('showForm', (value) => {
            if (value && this.bip) {
                this.openBip();
            } else {
                this.curHeader = undefined;
            }
        });
        this.$watch('backList', (value) => {
            if (value) {
                if (this.curHeader) {
                    this.updateBip();
                }
            }
        });
        this.$watch('saving', (value) => {
            if (value) {
                this.updateBip();
            }
            else {
                this.$set(this, 'bip', this.bipProjectData);
            }
        });
        this.$watch('updateMark', (value) => {
            if (value) {
                this.updateCriterias();
            }
        });
    }

    public beforeUnmount() {
        document.removeEventListener('click', this.closeOpenMore)
    }

    private closeOpenMore() {
        this.bipProjectData && this.bipProjectData.newFinace.forEach(el => el.openMore = false)
    }

    private get bipBall(): number {
        let res = 0;
        if (this.bipProjectData) {
            if (this.bipProjectData.calcResult) {
                for (const [key, value] of Object.entries(this.bipProjectData.calcResult)) {
                    if (key !== 'totalResult') {
                        if (value) {
                            if (typeof (value) === 'string') {
                                res = res + parseFloat(value);
                            }
                            if (typeof (value) === 'number') {
                                res = res + value;
                            }
                        }
                    }
                }
            }
        }
        if (this.bipProjectData) {
            if (this.bipProjectData.calcResult) {
                if (this.bipProjectData.calcResult['dateStateExamination-result'] === 0) {
                    res = 0;
                }
            }
        }
        return Math.round(res * 1000) / 1000;
    }

    private get calcIndicators() {
        const result = this.bipLinkCriterias.filter((row: any) =>
            parseInt(row.begin_year) <= this.bipYear
            && (row.end_year === null || (parseInt(row.end_year) >= this.bipYear))
            && row.project_type === this.bipProjectData?.projectType.toString()
            && row.object_type === this.bipProjectData?.object.toString()
            && (this.bipProjectData
                && this.bipProjectData.localityObject
                && row.place === parseInt(this.bipProjectData.localityObject?.type_of_settlement))
        );
        result.sort(sortByField('code'));

        return result;
    }

    private get criteriaFields() {

        return [
            {
                label: this.getTFieldTitle('code'),
                key: 'code'
            },
            {
                label: this.getTFieldTitle('name'),
                key: 'name'
            },
            {
                label: this.getTFieldTitle('value'),
                key: 'value'
            },
            {
                label: this.getTFieldTitle('weight'),
                key: 'weight'
            },
            {
                label: 'Max',
                key: 'max'
            },
            {
                label: 'Min',
                key: 'min'
            },
            {
                label: this.getTFieldTitle('point'),
                key: 'calc'
            }
        ];
    }

    private get curGpList(): any [] {
        const result = this.gpList.filter((row: any) => row.code !== null && row.code_kato !== null
            && (row.code_kato.startsWith('00') || row.code_kato.startsWith(this.location.code))
            && new Date(row.start_date).getFullYear() <= this.bipYear
            && new Date(row.end_date).getFullYear() >= this.bipYear)
            .sort(sortByField('name_ru'));
        return result;
    }

    private get enterIndicators() {
        const result = this.bipLinkCriterias.filter((row: any) =>
            parseInt(row.begin_year) <= this.bipYear
            && (row.end_year === null || (parseInt(row.end_year) >= this.bipYear))
            && row.source_type === 'W'
            && row.operator === 'АБП'
            && row.project_type === this.bipProjectData?.projectType.toString()
            && row.object_type === this.bipProjectData?.object.toString()
            && (this.bipProjectData
                && this.bipProjectData.localityObject
                && row.place === parseInt(this.bipProjectData.localityObject?.type_of_settlement))
        );
        result.sort(sortByField('code'));

        return result;
    }

    private get journalFields(): any [] {

        return [
            {
                label: this.getTFieldTitle('date'),
                key: 'updateDate'
            },
            {
                label: this.getTFieldTitle('user'),
                key: 'userName'
            },
            {
                label: this.getFilterTitle('status'),
                key: 'name'
            },
            {
                label: this.getTFieldTitle('comments'),
                key: 'commentTxt'
            }
        ];
    }

    private get histLst() {
        const result: any[] = [];
        if (!this.agrObj) {
            return [];
        }
        const options: DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
        for (const el of this.histBase) {
            const tmp = Object.assign({}, el);
            tmp.updateDate = new Date(tmp.updateDate).toLocaleDateString('ru-RU', options);
            result.push(setNameLang(this.$i18n.locale, tmp));
        }
        if (this.agrObj.userId) {
            const tmp = Object.assign(this.agrObj.status_lang, this.agrObj);
            tmp.updateDate = new Date(tmp.updateDate).toLocaleDateString('ru-RU', options);
            result.push(setNameLang(this.$i18n.locale, tmp));
        }
        if (result.length > 0) {
            result.sort(sortByField('updateDate'));
        }
        return result;
    }


    private get finaceFields(): any [] {
        const res = [
            {
                label: getFilterTitle(this, 'prg'),
                key: 'prg'
            },
            {
                label: getFilterTitle(this, 'ppr'),
                key: 'ppr'
            },
            {
                label: this.getTFieldTitle('spf'),
                key: 'spf'
            }
        ];
        if (this.bipProjectData) {
            for (let i = this.bipProjectData.period.begYear; i <= this.bipProjectData.period.endYear; i++) {
                res.push({
                    label: i.toString(),
                    key: i.toString()
                });
            }
            res.push(
                {
                    label: '...',
                    key: 'more'
                }
            );
        }
        const fields: any [] = [];
        Object.assign(fields, res);
        return res;
    }

    private get oldFinaceFields() {
        return [
            {
                label: getFilterTitle(this, 'prg'),
                key: 'prg'
            },
            {
                label: getFilterTitle(this, 'ppr'),
                key: 'ppr'
            },
            {
                label: getFilterTitle(this, 'spf'),
                key: 'spf'
            },
            {
                label: this.getCardTitle('sector_1', 'year'),
                key: 'year'
            },
            {
                label: this.getTFieldTitle('plan'),
                key: 'plan'
            },
            {
                label: this.getTFieldTitle('contractor'),
                key: 'podrad'
            },
            {
                label: this.getTFieldTitle('date_conclusion_contract'),
                key: 'date'
            },
            {
                label: this.getCardTitle('sector_1', 'total_cost') + '(' + this.getTFieldTitle('report') + ')',
                key: 'totalCoast'
            },
            {
                label: this.getTFieldTitle('SMR'),
                key: 'smp'
            },
            {
                label: this.getTFieldTitle('author_control'),
                key: 'control'
            },
            {
                label: this.getTFieldTitle('tech_supervision'),
                key: 'nadzor'
            },
            {
                label: '...',
                key: 'more'
            }
        ];
    }

    private get oldYearList() {
        const res: any [] = [];
        for (let y = this.variant.year - 1; y > 2009; y--) {
            res.push({value: y, text: String(y)});
        }
        return res;
    }

    private async loadBipHist(id: number) {
        try {
            const response: any = await fetch(`/api-py/get-bip-agree-hist/${id}`)
                .then((response) => response.json());
            await this.setUserNames(response);
            this.histBase = response;
        } catch (error) {
            makeToast(this, 'danger',
                getSmsTitle(this, 'error_request') + ' /get-bip-agree-hist', (error).toString());
        }
    }

    private addNewFin(finalAbp: number) {
        if (this.bipProjectData?.period) {
            const row = new RowNewFinanceData(this.bipProjectData?.period);
            if (finalAbp === 1) {
                if (this.editSection4) {
                    this.bipProjectData.newFinace.push(row);
                }
            } else {
                if (!this.reading) {
                    if (!this.bipProjectData.newFinaceRegional) {
                        this.$set(this.bipProjectData, 'newFinaceRegional', []);
                    }
                    this.bipProjectData.newFinaceRegional.push(row);
                }
            }
        }
    }

    private addOldFinace() {
        if (this.bipProjectData && !this.reading) {
            const row = new RowOldFinanceData(null, null, null, null, null, null, this.bipYear, 0);
            this.bipProjectData.oldFinace.push(row);
        }
    }

    private changeBipYear() {
        if (this.bipProjectData) {
            this.$set(this.bipProjectData.header, 'year', this.bipYear);
        }
    }

    private changeCode() {
        if (this.selectedBip) {
            this.bipProjectData = BipProjectDataClass.transit(this.variant, this.selectedBip);
            this.begYear = this.bipProjectData.period.begYear;
            this.endYear = this.bipProjectData.period.endYear;
            this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
            this.selObjectType = this.bipProjectData.object;
            this.getCurKatoRegion();

            if (this.selectedBip.files && this.selectedBip.files.length > 0) {
                this.transitFiles(this.selectedBip.header.year, this.selectedBip.code, this.bipYear.toString());
            }
        }
    } // при выборе кода БИПа с переходящих проектов

    private changeObjectType() {
        if (this.bipProjectData) {
            if (this.selObjectType !== this.bipProjectData.object
                && ['29'].includes(this.bipProjectData.object)
                && this.bipProjectData.kazPercent > 0) {
                this.$bvModal.msgBoxConfirm(
                    getSmsTitle(this, 'data_planned_kz_content_be_deleted'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                delete this.bipProjectData?.kazPercent;
                                delete this.bipProjectData['_kazPercent'];
                                this.$set(this.bipProjectData, '_kazPercent', 0);
                                this.selObjectType = this.bipProjectData.object;
                            }
                        } else {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'object', this.selObject);
                            }
                        }
                    }).catch(error => {
                        makeToast(this, 'danger',
                            getSmsTitle(this, 'error_validation') + ' ' + getFilterTitle(this, 'object_type'),
                            error.toString());
                    });
            }

            if (this.selObject.code !== this.bipProjectData.object
                && ['29'].includes(this.bipProjectData.object)
                && ((this.bipProjectData.numStateExpertise !== undefined
                        && this.bipProjectData.numStateExpertise?.length > 0)
                    || this.bipProjectData.dateStateExamination !== null)) {
                this.$bvModal.msgBoxConfirm(
                    getTFieldTitle(this, 'date') + getSmsTitle(this, 'and')
                    + getCardTitle(this, 'sector_1', 'state_examination_number') + ' ' + getSmsTitle(this, 'will_be_deleted'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'numStateExpertise', '');
                                this.$set(this.bipProjectData, '_dateStateExamination', null);
                                this.$set(this.bipProjectData, 'dateStateExamination', null);
                                this.selObjectType = this.bipProjectData.object;
                            }
                        } else {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'object', this.selObject);
                            }
                        }
                    }).catch(error => {
                        makeToast(this, 'danger', getSmsTitle(this, 'error_validation') + ' ' + getFilterTitle(this, 'object_type'),
                            error.toString());
                    });
            }
        }
    }

    private changePeriod() {
        if (this.bipProjectData) {
            this.$set(this.bipProjectData.period, '_begYear', this.begYear);
            this.$set(this.bipProjectData.period, '_endYear', this.endYear);
            this.begYear = this.bipProjectData.period.begYear;
            this.endYear = this.bipProjectData.period.endYear;
        }
    }

    private changeProjectType() {
        if (this.bipProjectData) {
            if (this.selDirection.code !== this.bipProjectData.projectType
                && ['1', '2', '5', '6'].includes(this.selDirection.code)
                && ((this.bipProjectData.numStateExpertise !== undefined
                        && this.bipProjectData.numStateExpertise?.length > 0)
                    || (this.bipProjectData.dateStateExamination !== null))) {
                this.$bvModal.msgBoxConfirm(
                    getTFieldTitle(this, 'date') + getSmsTitle(this, 'and')
                    + getCardTitle(this, 'sector_1', 'state_examination_number')
                    + getSmsTitle(this, 'will_be_deleted'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'numStateExpertise', '');
                                this.$set(this.bipProjectData, '_dateStateExamination', null);
                                this.$set(this.bipProjectData, 'dateStateExamination', null);
                                this.$set(this.bipProjectData, 'projectType', this.selDirection.code);
                            }
                        } else {
                            if (this.bipProjectData && this.directionList) {
                                this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
                            }
                        }
                    }).catch(error => {
                        makeToast(this, 'danger',
                            getSmsTitle(this, 'error_validation') + ' ' + getCardTitle(this, 'sector_1', 'project_direction'),
                            error.toString());
                    });
            }

            if (this.selDirection.code !== this.bipProjectData.projectType
                && ['1', '2'].includes(this.selDirection.code)
                && this.bipProjectData.kazPercent > 0) {
                this.$bvModal.msgBoxConfirm(
                    getSmsTitle(this, 'data_planned_kz_content_be_deleted'),
                    {
                        title: getSmsTitle(this, 'confirmation'),
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: getBipTitle(this, 'btn.ok'),
                        cancelTitle: getBipTitle(this, 'btn.cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    }).then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                delete this.bipProjectData?.kazPercent;
                                delete this.bipProjectData['_kazPercent'];
                                this.$set(this.bipProjectData, 'kazPercent', 0);
                                this.$set(this.bipProjectData, 'projectType', this.selDirection.code);
                            }
                        } else {
                            if (this.bipProjectData && this.directionList) {
                                this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
                            }
                        }
                    }).catch(error => {
                        makeToast(this, 'danger', getSmsTitle(this, 'error_validation'), error.toString());
                    });
            }
            this.$set(this.bipProjectData, 'projectType', this.selDirection.code);
        }
    }

    private changeRegion() {
        if (this.bipProjectData) {
            this.bipProjectData.region = this.curKatoRegion.code;
            this.bipProjectData.localityObject = null;
        }
    }

    private changeConsideration() {
        if (this.bipProjectData
            && !this.bipProjectData.isConsiderationCentralGovernment
            && this.bipProjectData?.considerationGA > 0) {
            this.$bvModal.msgBoxConfirm(
                getCardTitle(this, 'sector_1', 'request_amount') + ' ' + getSmsTitle(this, 'will_be_deleted'),
                {
                    title: getSmsTitle(this, 'confirmation'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: getBipTitle(this, 'btn.ok'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                }).then(value => {
                    if (value) {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'considerationGA', 0);
                        }
                    } else {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'isConsiderationCentralGovernment', true);
                        }
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_validation'), error.toString());
                });
        }
    }

    private changeDirectCash() {
        if (this.bipProjectData
            && !this.bipProjectData.isDirectCash
            && this.bipProjectData?.directCash > 0) {
            this.$bvModal.msgBoxConfirm(
                getCardTitle(this, 'sector_1', 'EIRR') + ' ' + getSmsTitle(this, 'will_be_deleted'),
                {
                    title: getSmsTitle(this, 'confirmation'),
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: getBipTitle(this, 'btn.ok'),
                    cancelTitle: getBipTitle(this, 'btn.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                }).then(value => {
                    if (value) {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'directCash', 0);
                        }
                    } else {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'isDirectCash', true);
                        }
                    }
                }).catch(error => {
                    makeToast(this, 'danger', getSmsTitle(this, 'error_validation'), error.toString());
                });
        }
    }

    private deleteItem(item: RowOldFinanceData) {
        let i = 0;
        if (this.bipProjectData?.oldFinace) {
            for (const it of this.bipProjectData.oldFinace) {
                if (it === item) {
                    this.bipProjectData.oldFinace.splice(i, 1);
                    return;
                }
                i++;
            }
        }
    }

    private async deleteNewFin(code: string, abp: number, version: string, item: RowNewFinanceData, arr: RowNewFinanceData[]) {
        await this.checkFinCost(code, abp, getNumber(item['prg']),
            item.ppr, getNumber(item.spf), this.variant.year, version)
            .then((result) => {
                if (getNumber(result) > 0) {
                    makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'delete_selected_prg_ppr_spf_impossible'));
                    return;
                } else {
                    let i = 0;
                    for (const row of arr) {
                        if (row === item) {
                            arr.splice(i, 1);
                            break;
                        }
                        i++;
                    }
                }
            });
    }

    private getCurKatoRegion(): any {
        this.curKatoRegion = {localityList: [{code: ''}], code: ''};

        if (this.bipProjectData && this.regionList) {
            for (const region of this.regionList) {
                if (this.bipProjectData.region === region.code) {
                    this.curKatoRegion = region;
                }
            }
        }
    } // определяет элемент(выбранного БИПа) по коду като (region)

    private getItem(code: any, field: string, list: any []) {
        return findItem(code, field, list);
    } // возвращает объект по коду с заданного списка

    private async getFiles(data: any) {
        if (this.bipProjectData) {
            this.bipProjectData.files = data;
        }
    } // получает обновленный список файлов

    private getNumber(value: number | string | boolean | undefined | null, digit: number = 1): number {
        return getNumber(value, digit);
    } // возращает число

    // форматирует введенное значение до digit цифр после запятой
    private inputFixed(item: any, field: any, value: any, digit: any) {
        value = value.toString().replace(/\s/g, '');
        this.$set(item, field, getNumber(value, digit));
    }

    private keyPress(event: any, pattern: any) {
        const regex = new RegExp(pattern);
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    private async openBip() {
        this.curHeader = undefined;
        this.histBase = [];
        if (this.showForm && this.bip) {
            this.bipProjectData = BipProjectDataClass.fromJSON(this.bip);
            if (this.bipProjectData) {
                if (this.bipProjectData.id === 0) {
                    this.bipProjectData.header.finalAbp = 1;
                    this.$set(this.bipProjectData.period, '_begYear', this.bipProjectData.header.year);
                    this.$set(this.bipProjectData.period, '_endYear', this.bipProjectData.period.begYear + 1);
                } else {
                    if (this.bipProjectData && this.bipProjectData.newProject === 0 && this.prevBipList) {
                        this.selectedBip = this.prevBipList.filter(row => row.code === this.bipProjectData?.code)[0];
                    }
                    if (this.bipProjectData && this.bipProjectData.addDatas.status) {
                        this.agrObj = this.bipProjectData.addDatas.status;
                        await this.loadBipHist(this.agrObj.id);
                    }
                }
                this.setCurParams();
                await checkFinCosts(this, this.bipProjectData);
            }
        }
    }

    private reWriteBip() {
        if (this.bipProjectData?.newProject === 1) {
            if (this.bipProjectData.id === 0) {
                this.bipProjectData = new BipProjectDataClass();
                this.bipProjectData.header.year = this.bipYear;
                this.bipProjectData.header.dataType = 1;
                this.bipProjectData.header.finalAbp = 1;
                this.$set(this.bipProjectData.period, '_begYear', this.bipProjectData.header.year);
                this.$set(this.bipProjectData.period, '_endYear', this.bipProjectData.period.begYear + 1);
                this.setCurParams();
            }
        } else {
            this.selectedBip = null;
        }
    } // при изменении характера расходов

    private setCurParams() {
        if (this.bipProjectData) {
            this.bipYear = this.bipProjectData.header.year;
            this.begYear = this.bipProjectData.period.begYear;
            this.endYear = this.bipProjectData.period.endYear;
            this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
            this.selObjectType = this.bipProjectData.object;
            this.getCurKatoRegion();
        }
    }

    // фиксация header
    private setHeader(data: any) {
        if (!(data && this.variant) || !data.selAbp || !data.selGu) {
            return;
        }
        const form = this.bipProjectData;

        const head: any = {
            year: this.variant.year,
            dataType: form.header.dataType,
            abp: data.selAbp.abp,
            gu: data.selGu,
            finalAbp: data.finalAbp,
            edited: form.header?.edited,
            finCost: form.header?.finCost
        };

        let editing = (head.abp !== form.header.abp);
        if (editing && form.newFinace) {
            form.newFinace.forEach((fin: any) => {
                ['gr', 'abp', 'prg', 'ppr'].forEach(field => {
                    this.$set(fin, field, null);
                })
            })
        }

        if (head.finalAbp === 1) {
            form.newFinaceRegional = [];
            this.$set(form, 'district_budget', '');
            this.$set(form, 'variant_recipient', '');
        } else {
            const regional: any = {
                abp: data.regAbp.abp,
                gu: data.regGu,
                dataType: (form.id === 0 || !form.header.regional ? this.variant.data_type : parseInt(form.header.regional.dataType)),
                finCost: form.header.regional?.finCost
            }
            this.$set(head, 'regional', regional);

            editing = (head.regional && form.header.regional && head.regional.abp !== form.header.regional.abp);

            if (editing && form.newFinaceRegional) {
                form.newFinaceRegional.forEach((fin: any) => {
                    ['gr', 'abp', 'prg', 'ppr'].forEach(field => {
                        this.$set(fin, field, null);
                    })
                })
            }
        }
        this.$set(form, 'header', head);

        if (editing && (form.newFinace.length > 0 || form.newFinaceRegional.length > 0)) {

            this.$set(form.header, 'edited', editing);
            makeToast(this, 'warning', getSmsTitle(this, 'attention'),
                getSmsTitle(this, 'ABP_changed_resel_prg_ppr_section4'));
        }

        this.prgList = data.selAbp.child;
        if (data.finalAbp == 0 && data.regAbp !== null) {
            this.regPrgList = data.regAbp.child;
        }
        this.curHeader = data;
    }

    private setNewFinace(item: any, code: string, list: any) {
        const prgItem = findItem(code, 'prg', list);
        this.$set(item, 'gr', prgItem.value.gr);
        this.$set(item, 'pgr', prgItem.value.pgr);
        this.$set(item, 'ppr', null);
    }

    private setParams() {
        const form = this.bipProjectData;
        // определение области бюджета
        if (form?.header?.gu && ['02'].includes(form?.header?.gu.id_budget_type)) {
            this.$set(form, 'region_budget', this.location.region);
        }
        if (form?.header?.gu && ['03', '06'].includes(form?.header?.gu.id_budget_type)) {
            this.$set(form, 'region_budget', form?.header?.gu.id_region);
        }

        // определение региона бюджета
        if (form.header.finalAbp == 0 && form?.header?.regional) {
            if (form?.header?.regional?.gu && ['02'].includes(form?.header?.regional?.gu.id_budget_type)) {
                this.$set(form, 'district_budget', this.location.region);
            }
            if (form?.header?.regional?.gu && ['03', '06'].includes(form?.header?.regional?.gu.id_budget_type)) {
                this.$set(form, 'district_budget', form?.header?.regional?.gu.id_region);
            }
        } else {
            this.$set(form, 'district_budget', '');
            this.$set(form, 'variant_recipient', '');
        }

        // удаление лишних записей в json
        for (const obj of form?.newFinace) {
            for (const [key, value] of Object.entries(obj)) {
                if (!(['gr', 'pgr', 'abp', 'prg', 'ppr', 'spf', 'openMore'].includes(key)
                    || (parseInt(key) >= form.period.begYear
                        && parseInt(key) <= form.period.endYear)) && typeof (parseInt(key)) === 'number') {
                    delete obj[parseInt(key)];
                }
            }
        }

        // Районный (конечный)
        if (form.header?.finalAbp === 0 && form?.newFinaceRegional) {
            for (const obj of form?.newFinaceRegional) {
                for (const [key, value] of Object.entries(obj)) {
                    if (!(['gr', 'pgr', 'abp', 'prg', 'ppr', 'spf', 'openMore'].includes(key)
                        || (parseInt(key) >= form.period.begYear
                            && parseInt(key) <= form.period.endYear)) && typeof (parseInt(key)) === 'number') {
                        delete obj[parseInt(key)];
                    }
                }
            }
        }
    }

    private async setUserNames(histBase: any[]) {
        const nameMap = new Map();

        if (this.agrObj.userId) {
            nameMap.set(this.agrObj.userId, '');
        }
        for (const el of histBase) {
            let result: any = null;
            try {
                const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(el.userId)}`);
                result = await response.json();
                if (result !== null) {
                    nameMap.set(el.userId, (result.firstName ? result.firstName : '') + ' ' + (result.lastName ? result.lastName : ''));
                }
            } catch (error) {
                makeToast(this, 'danger',
                    getSmsTitle(this, 'error_request') + ' /get-realm-user ' + el.userId, error.toString());
            }
        }

        this.agrObj.userName = nameMap.get(this.agrObj.userId);
        for (const el of histBase) {
            el.userName = nameMap.get(el.userId);
        }
    }

    private transitFiles(year: string, code: string, new_year: string) {
        Ax(
            {
                url: '/api-py/file-transit/' + year + '/' + code + '/' + new_year,
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data: any) => {
                if (data.result === 'success') {
                    // makeToast(this, 'success', 'Удаление', 'Запись удалена');
                    console.log('transit success');
                }
            },
            (error) => {
                makeToast(this, 'danger', getSmsTitle(this, 'error_download'), error.toString());
            }
        );
    }

    private async updateBip() {
        if (!this.curHeader) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'resel_datas')
                + getFilterTitle(this, 'ABP') + getSmsTitle(this, 'and')
                + getCardTitle(this, 'sector_1', 'st_establishment'));
            this.$emit('updateBip', {equal: false, form: undefined, noSave: true});
            return;
        }
        let save = true;
        const form = this.bipProjectData;
        this.setHeader(this.curHeader);
        this.setParams();

        //-------------------проверки--------------//
        if ((this.curHeader.selAbp === null || this.curHeader.selGu === null)
            || (this.curHeader.finalAbp === 0 && (this.curHeader.regAbp === null || this.curHeader.regGu === null))) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'resel_datas')
                + getCardTitle(this, 'sector_1', 'st_establishment'));
            save = save && false;
        }

        if (form.object.length === 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'fill_datas')
                + getFilterTitle(this, 'object_type') + getSmsTitle(this, 'and') + getTFieldTitle(this, 'project_name'));
            save = save && false;
        }

        if (form.isConsiderationCentralGovernment && form.considerationGA === 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'fill_datas') + getCardTitle(this, 'sector_1', 'request_amount'));
            save = save && false;
        }

        if (form.isDirectCash && form.directCash === 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'fill_datas') + getCardTitle(this, 'sector_1', 'EIRR'));
            save = save && false;
        }

        if (form.files.filter(row => row.text.length === 0).length > 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'fill_info_attached_docs') + '(' + getCardTitle(this,  'sector_1', 'title') + ')');
            save = save && false;
        }

        if (checkFinDoubles(form.oldFinace, ['prg', 'ppr', 'spf', 'year']) > 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'double_prg_ppr') + '(' + getCardTitle(this, 'sector_3', 'title') + ')');
            save = save && false;
        }

        if (checkFinDoubles(form.newFinace, ['prg', 'ppr', 'spf']) > 0
            || checkFinDoubles(form.newFinaceRegional, ['prg', 'ppr', 'spf']) > 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'double_prg_ppr_spf') + '(' + getCardTitle(this, 'sector_4', 'title') + ')');
            save = save && false;
        }

        let section = 0;
        if (checkFinNulls(form.newFinace, this.prgList) > 0
            || checkFinNulls(form.newFinaceRegional, this.regPrgList) > 0) {
            section = 4;
        }

        if ((form.header.finalAbp === 1 && checkFinNulls(form.oldFinace, this.prgList) > 0)
            || (form.header.finalAbp === 0 && checkFinNulls(form.oldFinace, this.regPrgList) > 0)){
            section = 3;
        }

        if (section > 0) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'sel_prg_ppr_spf_in') + ' ' + section + ' ' + getBipTitle(this, 'sector'));
            save = save && false;
        }

        if (this.checkBipDouble(form)) {
            const dataType = findItem(form.header.dataType, 'code', this.dataTypeList);

            let sms = 'Данные по проекту ' + form.code
                + ' с видом данных <<' + dataType.name + '>>'
                + ' за период ' + this.variant.year + '-' + this.variant.year + 2
                + ' уже присутсвуют в системе. Дублирующий проект не сохранится!';
            if (`${i18nService.locale}` === 'kk') {
                sms = form.code + 'жоба бойынша деректер,'
                    + '<<' + dataType.name + '>> деректер түрімен'
                    + this.variant.year + '-' + this.variant.year + 2 + 'жылдар кезеңінде'
                    + 'жүйеде бар. Қайталанатын жоба сақталмайды!';
            }
            if (`${i18nService.locale}` === 'en') {
                sms = 'Project data' + form.code
                + ' with data type <<' + dataType.name + '>>'
                + ' for the period ' + this.variant.year + '-' + this.variant.year + 2
                + ' are already present in the system. The duplicate project will not be saved!';
            }
            makeToast(this, 'danger', getSmsTitle(this, 'warning'), sms);
            save = save && false;
        }

        if (form.dateStateExamination === null && (!['1', '2', '5', '6'].includes(form.projectType))) {
            makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                getSmsTitle(this, 'fill_datas') + getCardTitle(this, 'sector_1', 'state_examination_date'));
            save = save && false;
        }

        setTimeout(() => {
            this.$emit('updateBip', {
                form: this.bipProjectData,
                equal: BipProjectDataClass.isEqual(this.bip, this.bipProjectData),
                save: save,
                delete: false
            })}, 100);
    }

    private async updateCriterias() {
        for (const row of this.calcIndicators) {
            const item = Object.assign(new CriteriaIndicators(), row);
            const value = this.criteriaValues.get(getRowKey(item, ['code', 'link', 'begin_year']));
            if (value) {
                this.calcFlds.forEach((field) => {
                    if (field === 'value') {
                        if (this.bipProjectData
                            && this.bipProjectData.criteriaIndicators
                            && item.operator === 'CSI') {
                            this.$set(this.bipProjectData.criteriaIndicators, item?.code, getNumber(value[field], 2));
                        }
                    } else {
                        if (this.bipProjectData
                            && this.bipProjectData.criteriaIndicators) {
                            this.$set(this.bipProjectData.criteriaIndicators, item?.code + field, getNumber(value[field], 2));
                        }
                    }
                });
            }
            if (item.code === '41') {
                let x = 0;
                let y = 0;
                if (this.bipProjectData.header.finalAbp === 1) {
                    if (this.bipProjectData.newFinace) {
                        for (const obj of this.bipProjectData.newFinace) {
                            for (const [key, value] of Object.entries(obj)) {
                                if (parseInt(key) === parseInt(this.bipProjectData?.header?.year)) {
                                    if (['15', '28'].includes(String(obj?.ppr))) {
                                        x += parseFloat(value);
                                    }
                                    y += parseFloat(value);
                                }
                            }
                        }
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code, getNumber((x / y) * 100, 2));
                    }
                } else {
                    if (this.bipProjectData.newFinaceRegional) {
                        for (const obj of this.bipProjectData.newFinaceRegional) {
                            for (const [key, value] of Object.entries(obj)) {
                                if (parseInt(key) === parseInt(this.bipProjectData?.header?.year)) {
                                    if (['15', '28'].includes(String(obj?.ppr))) {
                                        x += parseFloat(value);
                                    }
                                    y += parseFloat(value);
                                }
                            }
                        }
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code, getNumber((x / y) * 100, 2));
                    }
                }
            }
            if (item.code === '43') {
                if (this.bipProjectData && this.bipProjectData.criteriaIndicators) {

                    if ((['27'].includes(this.bipProjectData.object) && ['1', '3'].includes(this.bipProjectData.projectType))
                        || ['7', '8'].includes(this.bipProjectData.projectType)
                        || (['42', '43'].includes(this.bipProjectData.object) && ['1', '3'].includes(this.bipProjectData.projectType))) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['137']) / 1000);
                    }

                    if (['27', '42', '43'].includes(this.bipProjectData.object) && ['2', '4'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['56']) / 1000);
                    }

                    if (['7', '8'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['137']) / 1000);
                    }
                }
            }
            if (item.code === '46') {
                if (this.bipProjectData && this.bipProjectData.criteriaIndicators) {
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        100 * getNumber(this.bipProjectData.criteriaIndicators['137']) / getNumber(this.bipProjectData.criteriaIndicators['148']));

                }
            }
            if (item.code === '73') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        getNumber(this.bipProjectData.criteriaIndicators['147']) * getNumber(this.bipProjectData.criteriaIndicators['107']) / 1000);

                }
            }
            if (item.code === '50') {
                if (this.bipProjectData && this.bipProjectData.criteriaIndicators) {
                    if (!['17', '18', '31', '33'].includes(this.bipProjectData.object) && ['3'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['63']));
                    }
                    if (!['17'].includes(this.bipProjectData.object) && ['4'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['153']));
                    }
                    if (['17'].includes(this.bipProjectData.object)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['49']));
                    }
                    if (['18', '31', '33'].includes(this.bipProjectData.object) && ['3'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData?.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['146']));
                    }

                }
            }
            if (item.code === '65') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        getNumber(this.bipProjectData.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['64']));

                }
            }
            if (item.code === '77') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    if (['1', '3'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            100 * getNumber(this.bipProjectData.criteriaIndicators['63']) / getNumber(this.bipProjectData.criteriaIndicators['73']));
                    }
                    if (['2', '4'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            100 * getNumber(this.bipProjectData.criteriaIndicators['153']) / getNumber(this.bipProjectData.criteriaIndicators['73']));
                    }
                }
            }
            if (item.code === '87') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    if (['3'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['150']));
                    }
                    if (['4'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData.costWithout) / getNumber(this.bipProjectData.criteriaIndicators['56']));
                    }

                }
            }
            if (['89', '90'].includes(item.code)) {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        getNumber(this.bipProjectData.criteriaIndicators['64']) / getNumber(this.bipProjectData.criteriaIndicators['145']));
                }
            }
            if (item.code === '118') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    if (['3'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            getNumber(this.bipProjectData.criteriaIndicators['146']));
                    }
                }
            }
        }
    }

    private async checkFinCost(code: string,
                               abp: number, prg: number, ppr: number | null, spf: number,
                               year: number, variant: string) {
       const params = {
           'code': code,
           'abp': abp,
           'prg': prg,
           'ppr': ppr,
           'spf': spf,
           'year': year,
           'variant': variant
       }
       try {
           const response: any = await fetch(`/api-py/check-cost-data/${encodeURI(JSON.stringify(params))}`)
               .then((response) => response.json());
           return response;
        } catch (error) {
            makeToast(this, 'danger', getSmsTitle(this, 'error_request') + ' /check-cost-data', (error).toString());
            return 0;
        }
    }

    private checkBipDouble(form: BipProjectDataClass) {
        const exist = this.bipList.filter(row => row.id !== form.id
            && parseInt(row.header.year) === parseInt(form.header.year)
            && row.code === form.code
            && parseInt(row.header.dataType) === parseInt(form.header.dataType)
            && row.variant === this.variant.variant_uuid);

        return (exist.length > 0);
    }

    private getBipTitle(field: string) {
        return getBipTitle(this, field);
    }

    private getCardTitle(sector: string, field: string) {
        return getCardTitle(this, sector, field);
    }

    private getFilterTitle(field: string) {
        return getFilterTitle(this, field);
    }

    private getTFieldTitle(field: string) {
        return getTFieldTitle(this, field);
    }

    private getSmsTitle(field: string) {
        return getSmsTitle(this, field);
    }
}
