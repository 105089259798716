<template>
    <div class="inner-container h-100">
        <!--     фильтры/кнопки     -->
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i>{{ getBipTitle('filter.title') }}</span>
                    <span class="rc"><i class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>{{ getFilterTitle('title') }}</span>
                        <i
                            class="icon icon-close"
                            @click="$refs.drop.hide(true)"
                        ></i>
                    </div>
                    <!--------------- Плановый период ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('plan_period')">
                            <multiselect
                                ref="bcPlanYear"
                                v-model="planPeriod"
                                track-by="name"
                                label="name"
                                :options="yearsFilter"
                                :searchable="false"
                                :allow-empty="false"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Область/район бюджета ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('region_budget')">
                            <multiselect
                                ref="bcRegionBudget"
                                v-model="selRegionBudget"
                                track-by="text"
                                label="text"
                                :options="regionBudgetList"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Вид данных ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('data_type')">
                            <multiselect
                                ref="bcDataType"
                                v-model="selDataType"
                                track-by="name"
                                label="name"
                                :options="dataTypeList"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Версия бюджета ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('version')">
                            <multiselect
                                ref="bcVersion"
                                v-model="selVersion"
                                track-by="text"
                                label="text"
                                :options="versionFilter"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Месторасположение проекта ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('location')">
                            <multiselect
                                ref="bcRegion"
                                v-model="selRegion"
                                track-by="name"
                                label="name"
                                :placeholder="getFilterTitle('all')"
                                :options="regionFilter"
                                :searchable="true"
                                :show-labels="false"
                                @input="changeRegion">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Направление ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('direction')">
                            <multiselect
                                ref="bcDirection"
                                v-if="directionFilter"
                                v-model="selDirection"
                                track-by="name"
                                label="name"
                                :placeholder="getFilterTitle('all')"
                                :options="directionFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Тип объекта ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('object_type')">
                            <multiselect
                                ref="bcObject"
                                v-if="objectFilter"
                                v-model="selObject"
                                track-by="name"
                                label="name"
                                :placeholder="getFilterTitle('all')"
                                :options="objectFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Статус ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('status')">
                            <multiselect
                                ref="bcStatus"
                                v-if="statusFilter"
                                v-model="selStatus"
                                track-by="name"
                                label="name"
                                :placeholder="getFilterTitle('all')"
                                :options="statusFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- АБП ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('ABP')">
                            <multiselect
                                ref="bcAbp"
                                v-model="selAbp"
                                track-by="text"
                                label="text"
                                :placeholder="getFilterTitle('all')"
                                :options="abpFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- ПРГ ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getTFieldTitle('prg')">
                            <multiselect
                                ref="bcPrg"
                                v-model="selPrg"
                                track-by="text"
                                label="text"
                                :placeholder="getFilterTitle('all')"
                                :options="prgFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- ППР ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getTFieldTitle('ppr')">
                            <multiselect
                                ref="bcPpr"
                                v-model="selPpr"
                                track-by="text"
                                label="text"
                                :placeholder="getFilterTitle('all')"
                                :options="pprFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Гос.программа ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('gov_program')">
                            <multiselect
                                ref="bcGp"
                                v-model="selGp"
                                track-by="name"
                                label="name"
                                :placeholder="getFilterTitle('all')"
                                :options="gpFilter"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Характер расходов ------------------->
                    <div class="filter-block">
                        <b-form-group :label="getFilterTitle('expenses_type')">
                            <multiselect
                                ref="bcRadio"
                                v-model="radio"
                                track-by="name"
                                label="name"
                                :placeholder="getFilterTitle('all')"
                                :options="expTypes"
                                :searchable="true"
                                :show-labels="false">
                            </multiselect>
                        </b-form-group>
                    </div>
                </div>
            </b-dropdown>
            <div class="filter-actions" v-if="editing">
                <b-button v-if="operationCode.length > 0"
                          variant="success"
                          @click="agreementEvent">
                    {{ getBipTitle('btn.actions') }}
                </b-button>
                <b-button variant="success"
                          @click="saveCosts(showBipList.filter((row) => row.addDatas.edited))">
                    {{ getBipTitle('btn.save') }}
                </b-button>
            </div>
        </div>

        <!--     Список проектов     -->
        <div v-show="!showForm" class="h-100">
            <!--        хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && planPeriod"
                      @click="openFilterByRef('bcPlanYear')">
                    {{ planPeriod.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selRegionBudget"
                      @click="openFilterByRef('bcRegionBudget')">
                    {{ selRegionBudget.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selDataType"
                      @click="openFilterByRef('bcDataType')">
                    {{ selDataType.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selVersion"
                      @click="openFilterByRef('bcVersion')">
                    {{ selVersion.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selRegion"
                      @click="openFilterByRef('bcRegion')">
                    {{ selRegion.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selDirection"
                      @click="openFilterByRef('bcDirection')">
                    {{ selDirection.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selObject"
                      @click="openFilterByRef('bcObject')">
                    {{ selObject.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selStatus"
                      @click="openFilterByRef('bcStatus')">
                    {{ selStatus.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selAbp"
                      @click="openFilterByRef('bcAbp')">
                    {{ selAbp.abp + '-' + getFilterTitle('ABP') }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selPrg"
                      @click="openFilterByRef('bcPrg')">
                    {{ padLeadingZeros(selPrg.prg, 2) }}-БП>
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selPpr"
                      @click="openFilterByRef('bcPpr')">
                    {{ selPpr.ppr }}-БПП
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && selGp"
                      @click="openFilterByRef('bcGp')">
                    {{ selGp.name }}
                </span>
                <span class="item-breadcrumb"
                      v-if="!openFilterWindow && radio"
                      @click="openFilterByRef('bcRadio')">
                    {{ radio.text }}
                </span>
            </div>
            <b-progress
                variant="success"
                v-show="pBar < 100"
                height="10px"
                :value="pBar"
                striped
                animated
            ></b-progress>
            <div class="table-container">
                <template v-if="showBipList.length > 0">
                    <b-table ref="bipList"
                             :fields="tableFields"
                             :items="showBipList"
                             :striped="true"
                             responsive="true"
                             bordered
                             head-variant="light"
                             sticky-header="true"
                             no-border-collapse>
                        <template #head(check)="scope">
                            <b-form-checkbox v-model="check"
                                             v-if="editing && operationCode.length > 0">
                            </b-form-checkbox>
                        </template>
                        <template #head(support)="scope">
                            <div class="green">{{ scope.label }}</div>
                        </template>
                        <template #head(not_support)="scope">
                            <div class="red">{{ scope.label }}</div>
                        </template>
                        <template #head(plan1)="scope">
                            <div> {{ getTFieldTitle('including') + parseInt(planPeriod.year)
                            + getCardTitle('sector_1', 'year').toLowerCase() + ', ' +  getCardTitle('sector_1', 'thousand_tg') }}
                            </div>
                        </template>
                        <template #head(plan2)="scope">
                            <div>
                                {{ getTFieldTitle('including') + (parseInt(planPeriod.year) + 1)
                            + getCardTitle('sector_1', 'year').toLowerCase() + ', ' +  getCardTitle('sector_1', 'thousand_tg') }}
                            </div>
                        </template>
                        <template #head(plan3)="scope">
                            <div>
                                {{ getTFieldTitle('including') + (parseInt(planPeriod.year) + 2)
                            + getCardTitle('sector_1', 'year').toLowerCase() + ', ' +  getCardTitle('sector_1', 'thousand_tg') }}
                            </div>
                        </template>
                        <template #cell(check)="data">
                            <template v-if="editing &&
                                    operationCode.length > 0 &&
                                    checkAccess(data.item)">
                                <b-form-checkbox v-model="data.item.check">
                                </b-form-checkbox>
                            </template>
                        </template>
                        <template #cell(code)="data">
                            <div class="small-text bold">{{ data.value }}</div>
                        </template>
                        <template #cell(name)="data">
                            <div class="text-left">
                                <a href="#" @click="openBip(data.item)">{{ data.item.name }}</a>
                            </div>
                            <!--                    :href="data.item.addDatas.link" target="_blank"-->
                        </template>
                        <template #cell()="data">
                            <div class="text-right">
                                {{ $n(getNumber(data.value)) }}
                            </div>
                        </template>
                        <template #cell(status)="data">
                            {{ data.item.addDatas.status
                                    ? data.item.addDatas.status.name
                                    : findItem(1, "code", statusList).name }}
                        </template>
                        <template #cell(abp)="data">
                            <div>{{ data.item.addDatas.abp.text }}</div>
                        </template>
                        <template #cell(prg)="data">
                            <div
                                class="in-table-grid-item-text"
                                v-for="(fin, index) in data.item.addDatas['finace']"
                                :key="'prg_' + index + data.item.id + fin.prg + fin.spf">
                                {{ padLeadingZeros(fin.prg, 3) }}
                            </div>
                        </template>
                        <template #cell(ppr)="data">
                            <div
                                class="in-table-grid-item-text"
                                v-for="(fin, index) in data.item.addDatas[
                                    'finace'
                                ]"
                                :key="
                                    'ppr_' +
                                    index +
                                    data.item.id +
                                    fin.ppr +
                                    fin.spf
                                "
                            >
                                {{
                                    fin.ppr === null
                                        ? "_"
                                        : padLeadingZeros(fin.ppr, 3)
                                }}
                            </div>
                        </template>
                        <template #cell(ppr_cost)="data">
                            <div
                                class="in-table-grid-item-text"
                                v-for="(fin, index) in data.item.addDatas[
                                    'finace'
                                ]"
                                :key="
                                    'cost_' +
                                    index +
                                    data.item.id +
                                    fin.ppr +
                                    fin.spf
                                "
                            >
                                {{ $n(getNumber(fin["ppr_cost"])) }}
                            </div>
                        </template>
                        <template #cell(plan1)="data">
                            <div class="in-table-grid-item-input">
                                <div class="text">
                                    <span
                                        v-for="(fin, index) in data.item
                                            .addDatas['finace']"
                                        :key="
                                            'div_' +
                                            index +
                                            data.item.id +
                                            fin.ppr +
                                            fin.spf
                                        "
                                    >
                                        {{ $n(fin["fact1"]) }}
                                    </span>
                                </div>
                                <div class="inputs">
                                    <b-form-input
                                        v-for="(fin, ind) in data.item.addDatas[
                                            'finace'
                                        ]"
                                        :key="
                                            ind +
                                            'inp_' +
                                            ind +
                                            data.item.id +
                                            fin.prg +
                                            fin.ppr +
                                            fin.spf
                                        "
                                        :disabled="
                                            !editing || !isNextStep(data.item)
                                        "
                                        :value="$n(getNumber(fin.plan1))"
                                        @change="(v) => (fin.plan1 = v)"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="
                                            keyPress(
                                                $event,
                                                '^\\d*\\.?\\d{0,9}$',
                                            )
                                        "
                                        @blur="
                                            inputFixed(
                                                data.item,
                                                fin,
                                                'plan1',
                                                fin.plan1,
                                            )
                                        "
                                    >
                                    </b-form-input>
                                </div>
                            </div>
                        </template>
                        <template #cell(plan2)="data">
                            <div class="in-table-grid-item-input">
                                <div class="text">
                                    <span
                                        v-for="(fin, index) in data.item
                                            .addDatas['finace']"
                                        :key="
                                            'div2_' +
                                            index +
                                            data.item.id +
                                            fin.ppr +
                                            fin.spf
                                        "
                                    >
                                        {{ $n(fin["fact2"]) }}
                                    </span>
                                </div>
                                <div class="inputs">
                                    <b-form-input
                                        v-for="(fin, ind) in data.item.addDatas[
                                            'finace'
                                        ]"
                                        :key="
                                            'inp2_' +
                                            ind +
                                            data.item.id +
                                            fin.prg +
                                            fin.ppr +
                                            fin.spf
                                        "
                                        :disabled="
                                            !editing || !isNextStep(data.item)
                                        "
                                        :value="$n(getNumber(fin.plan2))"
                                        @change="(v) => (fin.plan2 = v)"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="
                                            keyPress(
                                                $event,
                                                '^\\d*\\.?\\d{0,9}$'
                                            )
                                        "
                                        @blur="
                                            inputFixed(
                                                data.item,
                                                fin,
                                                'plan2',
                                                fin.plan2
                                            )
                                        "
                                    >
                                    </b-form-input>
                                </div>
                            </div>
                        </template>
                        <template #cell(plan3)="data">
                            <div class="in-table-grid-item-input">
                                <div class="text">
                                    <span
                                        v-for="(fin, index) in data.item
                                            .addDatas['finace']"
                                        :key="
                                            'div3_' +
                                            index +
                                            data.item.id +
                                            fin.ppr +
                                            fin.spf
                                        "
                                    >
                                        {{ $n(fin["fact3"]) }}
                                    </span>
                                </div>
                                <div class="inputs">
                                    <b-form-input
                                        v-for="(fin, ind) in data.item.addDatas[
                                            'finace'
                                        ]"
                                        :key="
                                            'inp3_' +
                                            ind +
                                            data.item.id +
                                            fin.prg +
                                            fin.ppr +
                                            fin.spf
                                        "
                                        :disabled="
                                            !editing || !isNextStep(data.item)
                                        "
                                        :value="$n(getNumber(fin.plan3))"
                                        @change="(v) => (fin.plan3 = v)"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="
                                            keyPress(
                                                $event,
                                                '^\\d*\\.?\\d{0,9}$'
                                            )
                                        "
                                        @blur="
                                            inputFixed(
                                                data.item,
                                                fin,
                                                'plan3',
                                                fin.plan3
                                            )
                                        "
                                    >
                                    </b-form-input>
                                </div>
                            </div>
                        </template>
                        <template #cell(total)="data">
                            <div
                                class="text-bold text-center"
                                style="font-size: 1.1rem"
                                :class="{
                                    'text-danger': parseFloat(data.value) < 4,
                                    'text-warning':
                                        parseFloat(data.value) >= 4 &&
                                        parseFloat(data.value) < 7,
                                    'text-success': parseFloat(data.value) >= 7,
                                }"
                            >
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(more)="data">
                            <b-dropdown dropleft class="more">
                                <template v-slot:button-content>
                                    <i class="icon icon-more"></i>
                                </template>
                                <b-dropdown-item v-if="data.item.addDatas.status"
                                    @click="commentClk(data.item.addDatas.status)">
                                    {{ getCardTitle('sector_5', 'title') }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <template #bottom-row="data">
                            <td></td>
                            <td>
                                <div class="text-bold">
                                    {{ showBipList.length }}
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="text-right text-bold">
                                    {{ getTFieldTitle('total').toUpperCase() + ', ' + getCardTitle('sector_1', 'thousand_tg').toUpperCase() }}
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-bold">
                                    {{ $n(total("totalCost")) }}
                                </div>
                            </td>
                            <td colspan="4"></td>
                            <td>
                                <div class="text-right text-bold">
                                    {{ $n(totalFinans) }}
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-bold">
                                    {{ $n(_totalFinans("plan1")) }}
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-bold">
                                    {{ $n(_totalFinans("plan2")) }}
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-bold">
                                    {{ $n(_totalFinans("plan3")) }}
                                </div>
                            </td>
                            <td></td>
                        </template>
                    </b-table>
                </template>
            </div>
        </div>

        <!--     Форма БИПа     -->
        <div v-show="showForm">
            <!--     кнопки     -->
            <div class="filter-container">
                <div class="left-content"></div>
                <div class="right-content">
                    <div class="filter-actions">
                        <b-button class="ml-auto"
                                  variant="light"
                                  @click="showForm = !showForm">
                            {{ getBipTitle('btn.back_list') }}
                        </b-button>
                    </div>
                </div>
            </div>
            <bip-card :showForm="showForm"
                      :variant="selVersion"
                      :bip="bipProjectData"
                      :location="location"

                      :abp-base="abpBase"
                      :reg-abp-base="regAbpBase"
                      :spf-list="spfList"

                      :region-list="regionList"
                      :version-list="versionList"
                      :dataTypeList="dataTypeList"
                      :direction-list="directionList"
                      :gp-list="gpList"
                      :exp-types="expTypes"

                      :bip-cif-list="bipCifList"
                      :criteria-values="criteriaValues"
                      :bip-link-criterias="bipLinkCriterias"
                      :bip-link-types="bipLinkTypes"
                      :dict-unit="dictUnit">
            </bip-card>
        </div>

        <!--Модальные окна-->
        <c-budg-agr-modal ref="refAgrModal"
                          :agrBtnLst="agrBtnLst"
                          @agrClk="agrClick">
        </c-budg-agr-modal>
        <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>
        <loading :active="loading"
                 is-full-screen
                 spinner="bar-fade-scale"
                 color="#6495ED"/>
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueElementLoading from "vue-element-loading";
import store from "@/services/store";
import i18nService from "@/services/i18n";
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue";
import CBudgetAgreementHistModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue";
import BipCard from "@/modules/budget/bip/bip-card.vue";
import {
    BipProjectDataClass,
    sortByField,
    makeToast,
    years,
    padLeadingZeros,
    findItem,
    getNumber,
    checkAccess,
    filterVersion,
    defineBip,
    pprFinans,
    forInitiator,
    forRecipient,
    postData,
    versionByRegion,
    setCurVariant,
    getBipTitle, getCardTitle, getFilterTitle, getTFieldTitle, getSmsTitle, nameLocale, itemNameLocale
} from "./bip-types";

export default {
    name: "bip-uebp",
    components: {
        loading: VueElementLoading,
        "c-budg-agr-modal": CBudgetAgreementModal,
        "c-budg-agr-hist-modal": CBudgetAgreementHistModal,
        BipCard
    },
    props: {
        location: undefined,
        regionBudgetList: {
            type: Array,
            default: () => [],
        },
        versionList: {
            type: Array,
            default: () => [],
        },
        detailFields: {
            type: Array,
            default: () => [],
        },
        levels: {
            type: Array,
            default: () => [],
        },
        regLevels: {
            type: Array,
            default: () => [],
        },
        regionList: {
            type: Array,
            default: () => [],
        },
        dataTypeList: {
            type: Array,
            default: () => [],
        },
        directionList: {
            type: Array,
            default: () => [],
        },
        objectList: {
            type: Array,
            default: () => [],
        },
        statusList: {
            type: Array,
            default: () => [],
        },
        gpList: {
            type: Array,
            default: () => []
        },
        expTypes: {
            type: Array,
            default: () => []
        },

        bipCifList: {
            type: Array,
            default: () => []
        },
        criteriaValues: {
            type: Map,
            default: () => new Map()
        },
        bipLinkCriterias: {
            type: Array,
            default: () => []
        },
        bipLinkTypes: {
            type: Array,
            default: () => []
        },
        dictUnit: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            mode_code: "bip",

            ebkMap: new Map(),
            abpBase: [],
            regAbpBase: [],

            costsMap: null,
            agrMap: new Map(),

            planPeriod: null,
            selRegionBudget: null,
            selRegion: null, // текущий регион
            selKato: null, // текущий НП
            // dataTypeList: [],
            selDataType: null,
            selVersion: null,
            selDirection: null,
            selObject: null, // текущий тип объекта
            // statusList: [],
            selStatus: null, // текущий статус
            selAbp: null,
            selPrg: null,
            selPpr: null,
            selGp: null,

            radio: null,
            spfList: [],

            userAbps: [],
            userLevel: 0,
            operationCode: [],
            agrBtnLst: { back: [], forward: [] },
            agreeList: [],

            bipList: [],
            bipProjectData: null,

            open: false,
            check: false,
            showForm: false,
            openFilterWindow: false,
            loading: false,
            pBar: 0
        };
    },

    created() {
        console.log(new Date().toISOString(), "uebp created");

        this.$watch("regionBudgetList", (value) => {
            if (value.length > 0) {
                console.log(
                    new Date().toISOString(),
                    "uebp regionBudgetList = ",
                    value.length,
                );
                this.selRegionBudget = this.regionBudgetList[0];
                versionByRegion(this);
            }
        });

        this.$watch("planPeriod", (value) => {
            if (value) {
                console.log(
                    new Date().toISOString(),
                    "uebp planPeriod = ",
                    value,
                );
                setCurVariant(this);
            }
        });
        this.$watch("selRegionBudget", async (value) => {
            if (value) {
                console.log(
                    new Date().toISOString(),
                    "uebp selRegionBudget = ",
                    value,
                );
                setCurVariant(this);
            }
        });
        this.$watch("selDataType", (value) => {
            if (value) {
                console.log(new Date().toISOString(), "uebp selDataType = ", value);
                setCurVariant(this, true);
            }
        });
        this.$watch("selVersion", (value) => {
            this.selRegion = null;
            this.selDirection = null;
            this.selObject = null;
            this.selStatus = null;
            this.selAbp = null;
            this.selGp = null;
            this.bipList = [];

            if (value && !this.loading) {
                console.log(new Date().toISOString(), "uebp selVersion", this.selVersion);
                this.loading = true;

                this.loadBipPackage();
            }
        });

        this.$watch("selAbp", () => {
            this.selPrg = null;
            this.selPpr = null;
        });
        this.$watch("check", (value) => {
            for (const row of this.showBipList) {
                if (
                    checkAccess(
                        row,
                        "uebp_agree",
                        "oebp_agree_recipient",
                        this.selRegionBudget.code,
                    )
                ) {
                    this.$set(row, "check", value);
                }
            }
        });
        this.$watch("showBipList", (value) =>
            value.length ? this.submitReports() : null
        );
    },

    mounted() {
        console.log(new Date().toISOString(), "uebp mounted");

        this.loadOperations();
        this.loadUserAbps();
    },

    beforeUpdate() {
        for (const row of this.showBipList) {
            if (row._showDetails) {
                row._rowVariant = "info";
            }
        }
    },

    computed: {
        selectedLang() {
            return i18nService.locale;
        },

        userUiid() {
            return store.state.user.sub;
        }, // get global user Uiid

        editing() {
            return (
                [2, 3].includes(this.userLevel) &&
                this.selVersion &&
                this.selVersion.attribute
            );
        },

        totalFinans() {
            let sum = 0;
            for (const row of this.showBipList) {
                for (const fin of row?.addDatas["finace"]) {
                    sum += pprFinans(fin, row.period);
                }
            }
            return sum;
        }, // итого по заданному полю

        yearsFilter() {
            return years();
        },

        regionFilter() {
            const regions = new Set();

            for (const row of this.showBipList) {
                regions.add(row.region);
            }
            return this.regionList.filter((row) => regions.has(row.code));
        },

        versionFilter() {
            return filterVersion(this);
        }, // список для фильтра по версиям

        directionFilter() {
            const projects = new Set();

            for (const row of this.showBipList) {
                projects.add(parseInt(row.projectType));
            }
            return this.directionList.filter((row) =>
                projects.has(parseInt(row.code)),
            );
        },

        objectFilter() {
            const objects = new Set();

            for (const row of this.showBipList) {
                objects.add(parseInt(row.object));
            }
            return this.objectList.filter((row) =>
                objects.has(parseInt(row.code)),
            );
        },

        statusFilter() {
            const statuses = new Set();

            for (const row of this.showBipList) {
                if (row.addDatas.status) {
                    statuses.add(row.addDatas.status.code);
                }
            }

            return this.statusList.filter((row) => statuses.has(row.code));
        },

        abpFilter() {
            const abps = new Set();

            for (const row of this.showBipList) {
                abps.add(parseInt(row.addDatas.abp.abp));
            }

            return Array.from(this.ebkMap.values()).filter((row) =>
                abps.has(parseInt(row.abp)),
            );
        },

        gpFilter() {
            const gps = new Set();

            for (const row of this.showBipList) {
                for (const gp of row.governmentProgram) {
                    gps.add(gp.code);
                }
            }
            return this.gpList.filter((row) => gps.has(row.code));
        },

        prgFilter() {
            if (this.selAbp) {
                const prgs = new Set();
                for (const row of this.showBipList) {
                    for (const fin of row.addDatas["finace"]) {
                        prgs.add(parseInt(fin["prg"]));
                    }
                }
                return this.selAbp.value.child.filter((row) =>
                    prgs.has(parseInt(row.prg)),
                );
            }
            return [];
        },

        pprFilter() {
            if (this.selPrg) {
                const pprs = new Set();
                for (const bip of this.showBipList) {
                    for (const fin of [
                        ...bip.newFinace,
                        ...bip.newFinaceRegional,
                    ]) {
                        pprs.add(parseInt(fin.ppr));
                    }
                }
                return this.selPrg.value.child.filter((row) =>
                    pprs.has(parseInt(row.ppr)),
                );
            }
            return [];
        },

        showBipList() {
            let result = this.bipList;

            if (this.selVersion) {
                result = result.filter(
                    (row) => row.variant === this.selVersion.variant_uuid
                        || row.variant_recipient === this.selVersion.variant_uuid
                );
            }

            if (this.radio !== null) {
                switch (this.radio.value) {
                    case 0:
                        result = result.filter((row) => row.hasOwnProperty("newProject") && row.newProject === 0);
                        break;
                    case 1:
                        result = result.filter((row) => !row.hasOwnProperty("newProject") ||
                                (row.hasOwnProperty("newProject") && row.newProject === 1));
                        break;
                    default:
                        break;
                }
            }

            if (this.selRegion) {
                result = result.filter(
                    (row) => row?.region === this.selRegion.code
                );
            }

            if (this.selKato) {
                result = this.result.filter(
                    (row) =>
                        (row?.localityObject !== undefined &&
                            row?.localityObject.code === this.selKato.code) ||
                        row?.locality === this.selKato.code
                );
            }

            if (this.selDirection) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.projectType) ===
                        parseInt(this.selDirection.code),
                );
            }

            if (this.selObject !== null) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.object) === parseInt(this.selObject.code),
                );
            }

            if (this.selStatus !== null) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.addDatas.status.code) ===
                        parseInt(this.selStatus.code)
                );
            }

            if (this.selAbp) {
                result = result.filter(
                    (row) =>
                        parseInt(row.addDatas.abp.abp) ===
                        parseInt(this.selAbp.abp)
                );
            }

            const prgs = [];
            if (this.selPrg) {
                for (const bip of result) {
                    for (const fin of bip?.addDatas["finace"]) {
                        if (parseInt(this.selPrg.prg) === parseInt(fin["prg"])) {
                            prgs.push(bip.id);
                        }
                    }
                }
                result = result.filter((row) => prgs.includes(row.id));
            }

            const gps = [];
            if (this.selGp) {
                for (const bip of result) {
                    for (const gp of bip?.governmentProgram) {
                        if (this.selGp.code === gp.code) {
                            gps.push(bip.id);
                        }
                    }
                }
                result = result.filter((row) => gps.includes(row.id));
            }

            return result;
        },

        tableFields() {
            return [
                {
                    key: "check",
                    label: " "
                },
                {
                    key: "code",
                    label: getTFieldTitle(this, 'project_code'),
                    sortable: true
                },
                {
                    key: "name",
                    label: getTFieldTitle(this, 'project_name'),
                    sortable: true
                },
                {
                    key: "total",
                    label: getTFieldTitle(this, 'grade') + ', ' + getTFieldTitle(this, 'point'),
                    sortable: true
                },
                {
                    key: "totalCost",
                    label: getTFieldTitle(this, 'cost_project')  + ', ' + getCardTitle(this, 'sector_1', 'thousand_tg'),
                    sortable: true
                },
                {
                    key: "status",
                    label: getFilterTitle(this, 'status')
                },
                {
                    key: "abp",
                    label: getFilterTitle(this, 'ABP')
                },
                {
                    key: "prg",
                    label: getTFieldTitle(this, 'prg')
                },
                {
                    key: "ppr",
                    label: getTFieldTitle(this, 'ppr'),
                    variant: "warning"
                },
                {
                    key: "ppr_cost",
                    label: getTFieldTitle(this, 'cost_by_ppr')  + ', ' + getCardTitle(this, 'sector_1', 'thousand_tg'),
                },
                {
                    key: "plan1",
                    label: "",
                    variant: "warning"
                },
                {
                    key: "plan2",
                    label: ""
                },
                {
                    key: "plan3",
                    label: "",
                    variant: "warning"
                },
                {
                    key: "more",
                    label: ""
                }
            ]
        }
    },

    methods: {
        addNewStatus(
            old,
            data,
            bip_code,
            abp,
            gu,
            year,
            data_type,
            region,
            variant,
            comment_txt
        ) {
            data.bip_code = bip_code;
            data.abp = abp;
            data.gu = gu;
            data.cur_year = year;
            data.region = region;
            data.data_type = data_type;
            data.variant = variant;
            data.comment_txt = comment_txt;
            data.user_id = this.userUiid;
            return Object.assign({ old: old }, data);
        },

        async agreementEvent() {
            let firstStatus = null;
            let firstHeader = null;
            this.agreeList = [];
            this.agrBtnLst = { back: [], forward: [] };
            for (const row of this.showBipList) {
                if (row.check && row.addDatas.status) {
                    if (firstStatus === null) {
                        firstStatus = row.addDatas.status.code;
                        firstHeader = row.header.hasOwnProperty("regional");
                    }
                    if (firstStatus !== row.addDatas.status.code) {
                        makeToast(this, 'danger', getSmsTitle('coordination'),
                            getSmsTitle(this, 'sel_BIPs_with_identical_stat'));
                        return;
                    }
                    if (firstHeader !== row.header.hasOwnProperty("regional")) {
                        makeToast(this, 'danger', getSmsTitle('coordination'),
                            getSmsTitle(this, 'sel_BIPs_only_with_or_without_recipient'));
                        return;
                    }
                    this.agreeList.push(row.addDatas.status);
                }
            }
            if (this.agreeList.length === 0) {
                makeToast(this, 'warning', getSmsTitle('coordination'),
                    getSmsTitle(this, 'no_project_selected'));
                return;
            }

            const params = {
                modeCode: this.mode_code,
                operationCode: this.operationCode,
                agrCode: firstStatus,
            };
            let nextSteps = null;
            try {
                const response = await fetch(`/api-py/get-step-next-back/${encodeURI(
                        JSON.stringify(params))}`);
                nextSteps = await response.json();
                nameLocale(nextSteps);
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'error_request')
                    + ' /get-agreement-step-next-back', error.toString());
                return;
            }
            if (nextSteps.length === 0) {
                makeToast(this, 'warning', getSmsTitle('coordination'),
                    getSmsTitle(this, 'no_approval_steps_available'));
                return;
            }
            const back = [];
            const forward = [];
            for (const el of nextSteps) {
                if (el["stepType"] === 1) {
                    back.push(el);
                } else {
                    forward.push(el);
                }
            }
            this.$set(this.agrBtnLst, "back", back);
            this.$set(this.agrBtnLst, "forward", forward);

            this.$refs.refAgrModal.showEvent();
        },

        async agrClick(agree) {
            const newAgree = [];
            const checkList = this.showBipList.filter(row => row.check && row.addDatas.status);
            for (const bip of checkList) {
                if (bip.header?.finalAbp === 1) {
                    newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                        bip.code, bip.header.abp, bip.header.gu.code,
                        this.selVersion.year, this.selVersion.data_type,
                        this.selVersion.region_code, this.selVersion.variant_uuid,
                        agree.commentTxt));
                } else {
                    if (this.selRegionBudget.code === bip.region_budget) {
                        // console.log('сижу под Инициатором')
                        await forInitiator(this, 'uebp', bip, this.versionList)
                            .then((data) => {
                                if (data.versions_rec && data.versions_rec.length > 0 && data.findDB === 0) {
                                    // Статус Инициатора
                                    newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                        bip.code, bip.header.abp, bip.header.gu.code,
                                        this.selVersion.year, this.selVersion.data_type,
                                        this.selVersion.region_code, this.selVersion.variant_uuid,
                                        agree.commentTxt));
                                    // Статус Получателя
                                    newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                        bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                                        data.versions_rec[0].year, data.versions_rec[0].data_type,
                                        data.versions_rec[0].region_code, data.versions_rec[0].variant_uuid,
                                        agree.commentTxt));
                                    this.saveForm(bip, this.selVersion.variant_uuid, data.versions_rec[0].variant_uuid, false);
                                }
                            });
                     }

                    if (this.selRegionBudget.code === bip.district_budget) {
                        // console.log('сижу под Получателем')
                        await forRecipient(this, 'uebp', bip,  this.versionList)
                            .then((data) => {
                                if (data.versions && data.versions.length > 0 && data.findDB === 0) {
                                    // Статус Инициатора
                                    newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                        bip.code, bip.header.abp, bip.header.gu.code,
                                        data.versions[0].year, data.versions[0].data_type,
                                        data.versions[0].region_code, data.versions[0].variant_uuid,
                                        agree.commentTxt));
                                    // Статус Получателя
                                    newAgree.push(this.addNewStatus(bip.addDatas.status, agree,
                                        bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                                        this.selVersion.year, this.selVersion.data_type,
                                        this.selVersion.region_code, this.selVersion.variant_uuid,
                                        agree.commentTxt));
                                    this.saveForm(bip, data.versions[0].variant_uuid, this.selVersion.variant_uuid, false);
                                }
                            });
                    }
                }
            }

            await this.saveCosts(checkList);

            if (newAgree.length > 0) {
                try {
                    await postData('/api-py/set_bip_agreement_step', newAgree)
                        .then((response) => {
                            const list = response.json();

                            for (const res of list) {
                                if (res['result'] === 'error') {
                                    makeToast(this, 'danger', getSmsTitle(this, 'error_save'),
                                        `${res['errTxt']}`);
                                } else {
                                    if (res['bip_denied']) {
                                        for (const row of this.showBipList) {
                                            if (row.code === res['code']) {
                                                for (const fin of row.addDatas['finace']) {
                                                    ['plan1', 'plan2', 'plan3'].forEach((field) => this.$set(fin, field, 0));
                                                }
                                            }
                                        }
                                    }
                                    makeToast(this, 'success', getSmsTitle(this, 'saving'),
                                        getSmsTitle(this, 'success'));
                                }
                            }
                        });
                } catch (error) {

                } finally {
                    await this.loadBipAgreement();
                    setTimeout(() => {
                        this.updateAgreement();
                    }, 3000);
                }
            }
            for (const row of checkList) {
                this.$set(row, 'check', false);
            }
        },

        async changeRegion() {
            const katos = new Set();

            for (const row of this.showBipList) {
                if (
                    row.hasOwnProperty("localityObject") &&
                    row.localityObject
                ) {
                    katos.add(row.localityObject.code);
                }
            }

            if (this.selRegion) {
                this.selRegion.localityList =
                    this.selRegion.localityList.filter((row) =>
                        katos.has(row.code),
                    );
                this.selKato = null;
            }
        },

        checkAccess(bip) {
            return checkAccess(
                bip,
                "uebp_agree",
                "oebp_agree_recipient",
                this.selRegionBudget.code,
            );
        },

        chgData() {
            if (this.selVersion) {
                const data = {
                    year: this.planPeriod.year,
                    region: this.selRegionBudget.code,
                    dataType: parseInt(this.selDataType.code),
                    variant: this.selVersion.variant_uuid,
                };
                this.$emit("chgData", data);
            }
        },

        commentClk(agrObj) {
            if (agrObj !== null) {
                this.$set(agrObj, "mode_code", "bip");
                this.$refs.refHistModal.showEvent(agrObj);
            }
        },

        defineBipList(serverList) {
            try {
                for (const bip of serverList) {
                    const bipObject = BipProjectDataClass.fromJSON(bip);
                    if (
                        !bipObject?.isDeleted &&
                        ![35, 36, 37, 38].includes(parseInt(bipObject.object))
                    ) {
                        defineBip(this, bipObject, "uebp");
                        this.bipList.push(bipObject);
                        this.bipProjectData = bipObject;
                        this.pBar =
                            bipObject.id % 2 === 0
                                ? this.pBar + 20
                                : this.pBar - 20;
                        this.loading = true;
                    }
                }
            } catch (error) {
                console.log("defineBipList error => " + error);
            } finally {
                console.log(
                    new Date().toISOString(),
                    "uebp list",
                    this.bipList.length,
                );
                this.loading = false;
                this.pBar = 100;
            }

            // setTimeout(() => {
            //     if (this.bipList.length === 0) {
            //         this.defineBipList(serverList);
            //     }
            // }, 2000);
        },

        findItem(code, field, list) {
            return findItem(code, field, list);
        },

        getNumber(value, digit = 1) {
            return getNumber(value, digit);
        },

        async getSigners(params) {
            try {
                const response = await fetch("/api-py/get_signatories_data_by_budget_variants/",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        body: JSON.stringify(params)
                    }
                );
                return await response.json();
            } catch {
                makeToast(this, "danger", getSmsTitle(this, 'warning'),
                    getSmsTitle(this, 'error_request') + ' /get_signatories_data_by_budget_variants');
            }
        }, // список подписантов

        inputFixed(elem, item, field, value, digit = 1) {
            this.$set(item, field, getNumber(value, digit));
            this.$set(elem.addDatas, "edited", true);
        }, // форматирует введенное значение до digit цифр после запятой

        isNextStep(bip) {
            const result = [];
            if (bip && bip.addDatas.status && bip.addDatas.status.steps) {
                for (const step of bip.addDatas.status.steps) {
                    if (step.step_type === 2) {
                        result.push(step);
                    }
                }
            }
            return result.length > 0;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(
                !event.charCode ? event.which : event.charCode,
            );
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            return true;
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll("input"); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex((el) =>
                currentNode.isEqualNode(el),
            );
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBipAgreement(code = "") {
            const params = JSON.stringify({
                variant: this.selVersion.variant_uuid,
                mode_code: this.mode_code,
                operation_code: this.operationCode,
            });
            if (this.operationCode.length > 0) {
                try {
                    this.agrMap = new Map();
                    const response = await fetch(
                        `/api-py/get-bip-agreement-by-params/${encodeURI(
                            params,
                        )}`,
                    ).then((response) => response.json());
                    this.agrMap = new Map(Object.entries(response));
                    const bip = this.bipList.filter((row) => row.code === code);
                    if (bip.length > 0) {
                        const item = bip[0];
                        this.$set(item["addDatas"], "status", this.agrMap.get(code));
                    }
                } catch (error) {
                    makeToast(this, "danger", getSmsTitle(this, 'warning'),
                        getSmsTitle(this, 'error_request') + ' /get-bip-agreement-by-params');
                } finally {
                    console.log(new Date().toISOString(), "uebp agrMap", this.agrMap.size);
                }
            }
        },

        async loadEbkMap() {
            try {
                // fill abpBase
                this.abpBase = [];
                if (this.levels.length > 0 && this.ebkMap) {
                    for (const ebk of this.ebkMap.values()) {
                        itemNameLocale(ebk, 'abp');
                        itemNameLocale(ebk.value, 'abp');
                        for (const prg of ebk.value.child) {
                            itemNameLocale(prg, 'prg');
                            itemNameLocale(prg.value, 'prg');
                            for (const ppr of prg.value.child) {
                                itemNameLocale(ppr, 'ppr');
                                itemNameLocale(ppr.value, 'ppr');
                            }
                        }

                        if (this.levels.includes(ebk.value.budget_level_id)) {
                            this.abpBase.push(ebk.value);
                        }
                    }
                    this.abpBase.sort(sortByField("abp"));
                } else {
                    makeToast(this, "danger", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'no_access_level_by_initiator'));
                }

                // fill regAbpBase
                this.regAbpBase = [];
                if (this.regLevels.length > 0 && this.ebkMap) {
                    for (const ebk of this.ebkMap.values()) {
                        if (this.regLevels.includes(ebk.value.budget_level_id)) {
                            this.regAbpBase.push(ebk.value);
                        }
                    }
                    this.regAbpBase.sort(sortByField("abp"));
                } else {
                    makeToast(this, 'danger', getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'no_access_level_by_recipient'));
                }
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_request') + ' /cost-tree');
            } finally {
                console.log(new Date().toISOString(), 'grade ebkTree', this.ebkMap.size);
                console.log(new Date().toISOString(), 'grade abpBase = ', this.abpBase);
                console.log(new Date().toISOString(), 'grade regAbpBase = ', this.regAbpBase.length);
            }
        },

        async loadOperations() {
            try {
                const result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=004.003.002`))
                    .then((response) => response.json());

                if (result.operations) {
                    this.operationCode = result.operations;
                }
                if (result.accessLevel) {
                    this.userLevel = result.accessLevel;
                }
            } catch (error) {
                makeToast(this, 'danger', getSmsTitle(this, 'warning'),
                    getSmsTitle(this, 'error_request') + ' /api/um/module/link?user');
            } finally {
                console.log(new Date().toISOString(), "uebp loadOperations", this.operationCode, this.userLevel);
            }
        },

        async loadUserAbps() {
            try {
                const response = await fetch("/api-py/user-abp/" + this.userUiid)
                    .then((response) => response.json());
                for (const abp of response) {
                    this.userAbps.push(abp.abp);
                }
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'attention'),
                    getSmsTitle(this, 'error_request') + ' /user-abp');
            } finally {
                console.log(new Date().toISOString(), "uebp userAbps", this.userAbps.length);
            }
        }, // перечень доступных АБП пользователя

        async loadBipPackage() {
            const params = JSON.stringify({
                variant: this.selVersion.variant_uuid,
                mode_code: this.mode_code,
                operation_code: this.operationCode,
                mode: "uebp"
            });
            let serverList = [];
            this.pBar = 10;
            try {
                const response = await fetch(`/api-py/bip-package/${encodeURI(params)}`)
                    .then((response) => response.json());
                this.ebkMap = new Map(Object.entries(response.abpMap));

                this.spfList = response.spfList.sort(sortByField('spf')).filter((row) => row.type === 4);
                nameLocale(this.spfList, 'spf');

                this.agrMap = await new Map(Object.entries(response.agrMap));
                this.costsMap = await new Map(Object.entries(response.costsMap));

                serverList = await response.bipList;
            } catch (error) {
                makeToast(this, "danger", getSmsTitle(this, 'warning'),
                    getSmsTitle(this, 'error_request') + ' /bip-package');
            } finally {
                console.log(new Date().toISOString(), "uebp serverList", serverList.length);
                await this.loadEbkMap();

                if (serverList.length > 0 && this.ebkMap.size > 0) {
                    this.pBar = 45;
                    setTimeout(() => {
                        this.defineBipList(serverList);
                    }, 3000);
                } else {
                    makeToast(this, "warning", getSmsTitle(this, 'attention'),
                        getSmsTitle(this, 'no_datas_by_version'));
                    this.pBar = 100;
                    this.loading = false;
                }
            }
        },

        newCost(
            gr,
            pgr,
            abp,
            prg,
            ppr,
            spf,
            value,
            data_type,
            year,
            region,
            bip_code,
            cur_year,
            variant
        ) {
            return {
                gr: gr,
                pgr: pgr,
                abp: abp,
                prg: prg,
                ppr: ppr,
                spf: spf,
                value: value,
                data_type: data_type,
                year: year,
                region: region,
                bip_code: bip_code,
                cur_year: cur_year,
                variant: variant,
                user_name: this.userUiid
            };
        }, // формирует элемент для сохранение в БД

        openBip(item) {
            this.bipProjectData = item;
            this.showForm = true;
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        padLeadingZeros(num, size) {
            return padLeadingZeros(num, size);
        }, // добавляет 0-ли перед num до size-значного размера

        async saveCosts(list) {
            const costs = [];
            for (const row of list) {
                for (const fin of row.addDatas["finace"]) {
                    costs.push(
                        this.newCost(
                            fin.gr,
                            fin.pgr,
                            fin.abp,
                            fin.prg,
                            fin.ppr,
                            fin.spf,
                            fin.plan1,
                            this.selVersion.data_type,
                            this.planPeriod.year,
                            row.addDatas.region,
                            row.code,
                            this.planPeriod.year,
                            this.selVersion.variant_uuid,
                        ),
                    );
                    costs.push(
                        this.newCost(
                            fin.gr,
                            fin.pgr,
                            fin.abp,
                            fin.prg,
                            fin.ppr,
                            fin.spf,
                            fin.plan2,
                            this.selVersion.data_type,
                            this.planPeriod.year + 1,
                            row.addDatas.region,
                            row.code,
                            this.planPeriod.year,
                            this.selVersion.variant_uuid,
                        ),
                    );
                    costs.push(
                        this.newCost(
                            fin.gr,
                            fin.pgr,
                            fin.abp,
                            fin.prg,
                            fin.ppr,
                            fin.spf,
                            fin.plan3,
                            this.selVersion.data_type,
                            this.planPeriod.year + 2,
                            row.addDatas.region,
                            row.code,
                            this.planPeriod.year,
                            this.selVersion.variant_uuid,
                        ),
                    );
                }
            }
            if (costs.length > 0) {
                await this.saveVariant(costs);
            }
        }, // list for saving

        async saveForm(form, versions, versions_rec) {
            this.$set(form, "variant", versions);
            this.$set(form, "variant_recipient", versions_rec);
            if (form.versions) {
                form.versions.push(form.variant);
                form.versions.push(form.variant_recipient);
            }

            const params = JSON.stringify({
                variant: this.selVersion.variant_uuid,
                mode_code: this.mode_code,
                operation_code: this.operationCode,
            });

            const newElem = {
                form: form,
                variant: form.variant,
                variant_recipient: form.variant_recipient,
                user_name: this.userUiid,
                params: params,
            };
            await postData("/api-py/save-bip-form", newElem).then((data) => {
                if (data.result) {
                    makeToast(this, "success", "Сохранение", form.code + " сохранен");
                } else {
                    makeToast(this, "danger", getSmsTitle(this, 'error_save'),
                        form.code + " => " + data.error);
                }
            });
        },

        async saveVariant(values) {
            await postData("/api-py/save-cost-budget-data/", values)
                .then((result) => {
                    if (result.result === "success") {
                        makeToast(this, 'success', getSmsTitle(this, 'message'),
                            getSmsTitle(this, 'datas_saved'));
                    } else {
                        makeToast(this, "danger", getSmsTitle(this, 'warning'),
                            getSmsTitle(this, 'error_save'));
                    }
                },
            );
        }, // сохранение записей в БД

        submitReports() {
            this.submitReport();
            this.submitReport68();
            this.chgData();
        },

        submitReport() {
            const code = '0';
            const dataList = [];
            for (const bip of this.showBipList) {
                bip.total = bip.total ? bip.total : 0;
                const finList = this.setFinList(bip.addDatas["finace"]);

                if (bip.code === code) {
                    console.log('submitReport', bip.addDatas["finace"], finList);
                }
                const item = {
                    code: bip.code,
                    reg_loc: bip.addDatas.reg_loc,
                    name: bip.name,
                    exp_type: bip.addDatas.exp_type,
                    type_obj: bip.addDatas.type_obj,
                    program: bip.addDatas.program ?? "",
                    total: bip.total,
                    totalCost: bip.totalCost,
                    statusItem: bip.addDatas.status
                        ? bip.addDatas.status.name
                        : findItem(1, "code", this.statusList).name,
                    abp: bip.addDatas.abp.abp,
                    dataList: finList,
                };
                dataList.push(item);
            }

            // объект для передачи в отчет
            const params = {
                lang: `${i18nService.locale}`,
                planPeriod: this.planPeriod,
                year: this.planPeriod.year,
                selRegionBudget: this.selRegionBudget,
                selDataType: this.selDataType,
                selVersion: this.selVersion,
                selRegion: this.selRegion,
                selProject: this.selDirection,
                selObject: this.selObject,
                selStatus: this.selStatus,
                selAbp: this.selAbp,
                selPrg: this.selPrg ?? null,
                selPpr: this.selPpr,
                selGp: this.selGp,
                radio: this.radio,
                datas: dataList,
            };
            this.$emit("submitReport", params);
        },

        setFinList(finace) {
            const datas = [];
            for (const fin of finace) {
                let curFin = undefined;
                if (this.selPrg) {
                    if (fin.prg === getNumber(this.selPrg.prg)) {
                        if (this.selPpr) {
                            if (fin.ppr === getNumber(this.selPpr.ppr)) {
                                curFin = fin;
                            }
                        } else {
                            curFin = fin;
                        }
                    }
                } else {
                    curFin = fin;
                }

                if (curFin) {
                    const item = {};
                    ['prg', 'ppr', 'spf'].forEach((field) =>
                        this.$set(item, field, padLeadingZeros(curFin[field], 3)));

                    ['pprCost', 'fact1', 'fact2', 'fact3', 'plan1', 'plan2', 'plan3']
                        .forEach((field) => this.$set(item, field, curFin[field]));
                    datas.push(item);
                }
            }
            return datas;
        },

        async submitReport68() {
            if (!this.selAbp || !this.selPrg) {
                return this.$emit("submitReport68", null);
            }

            let gu = null;
            let len = 0;
            for (const bip of this.showBipList) {
                if (bip.variant === this.selVersion.variant_uuid) {
                    if (this.selAbp.abp === bip.header.abp) {
                        gu = bip.header.gu;
                    }
                    len += bip.newFinace.length;
                }

                if (bip.variant_recipient === this.selVersion.variant_uuid) {
                    if (this.selAbp.abp === bip.header.regional.abp) {
                        gu = bip.header.regional.gu;
                    }
                    len += bip.newFinaceRegional.length;
                }
            }

            /** Объект для передачи в отчет */
            const params = {
                year: this.planPeriod.year,
                data_type: this.selDataType.name,
                variant_uuid: this.selVersion.variant_uuid,
                abp: String(this.selAbp.abp),
                prg: this.selPrg.prg,
                lang: this.selectedLang,
                version_name: "",
                abp_name: "",
                prg_name: "",
                sign_code: ""
            };

            params.version_name = this.selVersion.name;
            params.abp_name = this.selAbp.value.name;
            params.prg_name = this.selPrg.value.name;

            if (len === 0) {
                makeToast(this, 'danger', getBipTitle(this, 'app') + ' 68',
                    getSmsTitle(this, 'information_financing_plan_period_not_filled'));
                return;
            }

            const signParams = {
                code_modules: "004.002.007",
                abp: this.selAbp.abp,
                code_prg: this.selPrg.prg,
                code_gu: gu?.code,
                code_forms: "",
                budget_variants: this.selVersion.variant_uuid
            };
            const signers = await this.getSigners(signParams);
            if (signers["sign_code"].length > 0) {
                signers["sign_code"].sort(sortByField("order_num"));
            }

            params.sign_code = signers.sign_code;
            this.$emit("submitReport68", params);
        },

        total(field, add = 0) {
            let sum = 0;
            for (const row of this.showBipList) {
                sum += getNumber(add === 0 ? row[field] : row.addDatas[field]);
            }
            return sum;
        }, // итого по заданному полю

        _totalFinans(field) {
            let sum = 0;
            for (const row of this.showBipList) {
                if (row.hasOwnProperty("addDatas") &&
                    row.addDatas.hasOwnProperty("finace")) {
                    for (const fin of row?.addDatas["finace"]) {
                        if (fin.hasOwnProperty(field)) {
                            sum += getNumber(fin[field]);
                        }
                    }
                }
            }
            return sum;
        }, // итого по заданному полю

        updateAgreement() {
            if (this.agrMap) {
                for (const key of this.agrMap.keys()) {
                    const bip = this.bipList.filter((row) => row.code === key);
                    if (bip.length > 0) {
                        const item = bip[0];
                        this.$set(item["addDatas"], "status", this.agrMap.get(key));
                    }
                }
            }
        },

        getBipTitle(field) {
            return getBipTitle(this, field);
        },

        getCardTitle(sector, field) {
            return getCardTitle(this, sector, field);
        },

        getFilterTitle(field) {
            return getFilterTitle(this, field);
        },

        getTFieldTitle(field) {
            return getTFieldTitle(this, field);
        },

        getSmsTitle(field) {
            return getSmsTitle(this, field);
        }
    }
};
</script>
